import { only } from '../helpers';
import { defineStore } from 'pinia';
import { useFactHouse } from './factHouse';

export const useFact = defineStore('fact', {
  state: () => ({
    facts: [],
  }),

  getters: {
    all: (state) => state.facts,
    count: (state) => state.facts.length,
  },

  actions: {
    set(fact) {
      const index = this.facts.findIndex((h) => h.id === fact.id);

      index > -1 ? (this.facts[index] = fact) : this.facts.push(fact);
    },
    byHouse(houseId) {
      return useFactHouse()
        .byHouse(houseId)
        .map((item) =>
          Object.assign(
            only(['finished_at', 'read_at', 'image'], item),
            this.facts.find((t) => t.id === item.fact_id),
          ),
        );
    },
  },
});
