import { defineStore } from 'pinia';

export const useAction = defineStore('action', {
  state: () => ({
    actions: [],
  }),

  getters: {
    all: (state) => state.actions,
  },

  actions: {
    removeById(id) {
      const index = this.actions.findIndex((h) => h.id === id);

      if (index === -1) {
        return;
      }

      this.actions[index] = undefined;
      this.actions = this.actions.filter((i) => i !== undefined);
    },
    set(action) {
      const index = this.actions.findIndex((h) => h.id === action.id);

      index > -1 ? (this.actions[index] = action) : this.actions.push(action);
    },
    async byIds(ids) {
      return new Promise((resolve) => {
        resolve(this.actions.filter((action) => ids.includes(action.id)));
      });
    },
    async byDay(dayId) {
      return new Promise((resolve) => {
        resolve(this.actions.filter((action) => action.day_id === dayId));
      });
    },
    async byDays(dayIds) {
      return new Promise((resolve) => {
        resolve(
          this.actions.filter((action) => dayIds.includes(action.day_id)),
        );
      });
    },
  },
});
