<template>
  <button>
    <slot />
  </button>
</template>

<style lang="postcss" scoped>
button {
  @apply rounded-full
    bg-black
    px-8
    font-bold
    uppercase

    text-white
    transition-colors

    active:text-blue-400
    disabled:bg-gray-300
    disabled:text-gray-500;

  height: 3.5rem;
}
</style>
