import { only } from '@/helpers';
import { defineStore } from 'pinia';
import { useExerciseHouse } from './exerciseHouse';

export const useExercise = defineStore('exercises', {
  state: () => ({
    exercises: [],
  }),

  getters: {
    all: (state) => state.exercises,
    count: (state) => state.exercises.length,
  },

  actions: {
    set(exercise) {
      const index = this.exercises.findIndex((h) => h.id === exercise.id);

      index > -1
        ? (this.exercises[index] = exercise)
        : this.exercises.push(exercise);
    },
    byHouse(houseId) {
      return useExerciseHouse()
        .byHouse(houseId)
        .map((item) =>
          Object.assign(
            only(['finished_at', 'read_at', 'image'], item),
            this.exercises.find((t) => t.id === item.exercise_id),
          ),
        );
    },
  },
});
