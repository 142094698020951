import { defineStore } from 'pinia';

export const useActionDay = defineStore('action_day', {
  state: () => ({
    actionDays: [],
  }),

  getters: {
    all: (state) => state.actionDays,
  },

  actions: {
    set(actionDay) {
      const index = this.actionDays.findIndex((h) => h.id === actionDay.id);

      index > -1
        ? (this.actionDays[index] = actionDay)
        : this.actionDays.push(actionDay);
    },
    removeById(id) {
      const index = this.actionDays.findIndex((h) => h.id === id);

      if (index === -1) {
        return;
      }

      this.actionDays[index] = undefined;
      this.actionDays = this.actionDays.filter((i) => i !== undefined);
    },
    remove(actionDay) {
      return this.removeById(actionDay.id);
    },
    byDay(dayId) {
      return this.actionDays.filter((actionDay) => actionDay.day_id === dayId);
    },
    byAction(actionId) {
      return this.actionDays.filter(
        (actionDay) => actionDay.action_id === actionId,
      );
    },
  },
});
