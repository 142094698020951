import { defineStore } from 'pinia';

export const useActionResource = defineStore('action_resource', {
  state: () => ({
    actionResources: [],
  }),

  getters: {
    all: (state) => state.actionResources,
  },

  actions: {
    set(actionResource) {
      const index = this.actionResources.findIndex(
        (h) => h.id === actionResource.id,
      );

      index > -1
        ? (this.actionResources[index] = actionResource)
        : this.actionResources.push(actionResource);
    },
    removeById(id) {
      const index = this.actionResources.findIndex((h) => h.id === id);

      if (index === -1) {
        return;
      }

      this.actionResources[index] = undefined;
      this.actionResources = this.actionResources.filter(
        (i) => i !== undefined,
      );
    },
    remove(actionResource) {
      return this.removeById(actionResource.id);
    },
    async byActions(actionIds) {
      return new Promise((resolve) => {
        resolve(
          this.actionResources.filter((actionResource) =>
            actionIds.includes(actionResource.action_id),
          ),
        );
      });
    },
  },
});
