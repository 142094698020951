import { defineStore } from 'pinia';

export const useResource = defineStore('resource', {
  state: () => ({
    resources: [],
  }),

  getters: {
    all: (state) => state.resources,
    getByIds: (state) => (ids) =>
      state.resources.filter((resource) => ids.includes(resource.id)),
  },

  actions: {
    set(resource) {
      const index = this.resources.findIndex((h) => h.id === resource.id);

      index > -1
        ? (this.resources[index] = resource)
        : this.resources.push(resource);
    },
  },
});
