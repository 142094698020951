import { v5 } from 'uuid';
import levels from './data/levels.json';
import cards from './data/cards.json';
import { useFact } from './stores/fact';
import { useExercise } from './stores/exercise';
import { useQuestion } from './stores/question';
import { useThing } from './stores/thing';
import { useActionDay } from './stores/actionDay';
import { useHouse } from './stores/house';

export function log(...args) {
    // Do not show log messages in production.
    if (import.meta.env.PROD) {
        return;
    }

    console.log(...args);
}

export function warn(...args) {
    // Do not show log messages in production.
    if (import.meta.env.PROD) {
        return;
    }

    console.warn(...args);
}

export function debug(...args) {
    // Do not show log messages in production.
    if (import.meta.env.PROD) {
        return;
    }

    console.debug(...args);
}

export function table(...args) {
    // Do not show log messages in production.
    if (import.meta.env.PROD) {
        return;
    }

    console.table(...args);
}

export function time(name, callback) {
    if (import.meta.env.PROD) {
        return callback();
    }

    console.time(name);

    let t = callback();

    console.timeEnd(name);

    return t;
}

export const nullGUID = '00000000-0000-0000-0000-000000000000';

export function only(keys, object) {
    return Object.fromEntries(
        Object.entries(object).filter((t) => keys.includes(t[0])),
    );
}

export class QueryBuilder {
    constructor() {
        this.current = [];
    }

    /**
     *
     * @param {any[]} array The first rows
     * @param {string} as The name.
     * @returns
     */
    from(array, as) {
        this.current = array.map((r) => Object.fromEntries([[as, r]]));
        return this;
    }

    /**
     *
     * @param {any[]} array The array.
     * @param {string} as Name of the join.
     * @param {(row: any) => boolean} on Where to join.
     * @returns
     */
    join(array, as, on) {
        this.current = this.current.reduce(
            (c, i) =>
                c.concat(
                    array
                        .map((t) => {
                            return Object.fromEntries(
                                [[as, t]].concat(Object.entries(i)),
                            );
                        })
                        .filter((t) => on(t)),
                ),
            [],
        );
        return this;
    }

    /**
     *
     * @param {(row: any) => boolean} on
     * @returns {this}
     */
    where(on) {
        this.current = this.current.filter((t) => on(t));
        return this;
    }

    reduce(callbackfn) {
        this.current = this.current.reduce(callbackfn, []);
    }

    /**
     *
     * @returns {any[]}
     */
    get() {
        return this.current;
    }
}

function sum(start, end, callback) {
    var c = 0;
    for (var i = start; i <= end; i++) {
        c += callback(i);
    }
    return c;
}

export function shouldGiveCards(counter) {
    warn('shouldGiveCards is deprecated');
    let c = 6; // Base amount of action for a level.
    let u1 = 1; // The gain for amount of action.
    let u = 5; // Add the gain every n level.
    let g = 1; // Base offset of actions.

    let check = (x) => sum(0, x, (x) => Math.floor(c + u1 * ((x - g) / u))) - 5;

    for (let i = 0; i < 500; i++) {
        if (check(i) > counter) {
            return i;
        }
    }

    // infinte loop
    return 500;
}

export function getXPStats() {
    const storeHouse = useHouse();
    const storeFacts = useFact();
    const storeExercises = useExercise();
    const storeQuestions = useQuestion();
    const storeThings = useThing();
    const storeActionDay = useActionDay();

    const house = storeHouse.house;

    let factsFinishedPoints = storeFacts
        .byHouse(house)
        .filter(
            (t) => t.finished_at !== null && t.lesson_type === 'explore',
        ).length;
    let exercisesFinishedPoints = storeExercises
        .byHouse(house)
        .filter(
            (t) => t.finished_at !== null && t.lesson_type === 'explore',
        ).length;
    let questionsFinishedPoints = storeQuestions
        .byHouse(house)
        .filter(
            (t) => t.finished_at !== null && t.lesson_type === 'explore',
        ).length;
    let thingsPoints = storeThings.all.reduce(
        (c, i) => c + Math.min(i.amount, 1) + Math.max((i.amount - 1) / 5, 0),
        0,
    );

    let actionPoints = storeActionDay.all.length;

    // Fake the xp for development.
    if (window.points && !import.meta.env.PROD) {
        return {
            factsFinishedPoints,
            exercisesFinishedPoints,
            questionsFinishedPoints,
            thingsPoints,
            actionPoints,
            totalPoints: window.points,
        };
    }

    return {
        factsFinishedPoints,
        exercisesFinishedPoints,
        questionsFinishedPoints,
        thingsPoints,
        actionPoints,
        totalPoints:
            factsFinishedPoints +
            exercisesFinishedPoints +
            questionsFinishedPoints +
            thingsPoints +
            actionPoints,
    };
}

export function getXP() {
    return getXPStats().totalPoints;
}

export function getCurrentLevel(xp) {
    let index = 0;

    while (index < levels.length && levels[index].xp <= xp) {
        index++;
    }

    return index;
}

export function getNextLevelCard(xp) {
    const level = getCurrentLevel(xp) + 1;
    const next = cards.filter((card) => card.batch == level);

    if (next.length === 0) {
        return null;
    }

    return next[0];
}

export function getLessonLevel(lesson) {
    const storeFacts = useFact();
    const storeExercises = useExercise();
    const storeQuestions = useQuestion();

    let factsFinished = storeFacts
        .byHouse(nullGUID)
        .filter((t) => t.finished_at !== null && t.lesson_type === lesson)
        .map((t) => t.batch);
    let exercisesFinished = storeExercises
        .byHouse(nullGUID)
        .filter((t) => t.finished_at !== null && t.lesson_type === lesson)
        .map((t) => t.batch);
    let questionsFinished = storeQuestions
        .byHouse(nullGUID)
        .filter((t) => t.finished_at !== null && t.lesson_type === lesson)
        .map((t) => t.batch);

    const currentLevel = factsFinished
        .concat(exercisesFinished)
        .concat(questionsFinished)
        .reduce((c, i) => (c < i ? i : c), 0);

    return currentLevel;
}

export function getLessonNextCards(lesson) {
    const storeFacts = useFact();
    const storeExercises = useExercise();
    const storeQuestions = useQuestion();

    const level = getLessonLevel(lesson) + 1;

    // Get all cards for the lesson.
    let facts = storeFacts.all
        .filter((t) => t.lesson_type === lesson)
        .map((t) => {
            t.type = 'fact';
            return t;
        });
    let exercises = storeExercises.all
        .filter((t) => t.lesson_type === lesson)
        .map((t) => {
            t.type = 'exercise';
            return t;
        });
    let questions = storeQuestions.all
        .filter((t) => t.lesson_type === lesson)
        .map((t) => {
            t.type = 'question';
            return t;
        });

    // Get the current level.
    const factsCurrent = storeFacts
        .byHouse(nullGUID)
        .filter((t) => t.lesson_type === lesson)
        .map((t) => t.batch);
    const exercisesCurrent = storeExercises
        .byHouse(nullGUID)
        .filter((t) => t.lesson_type === lesson)
        .map((t) => t.batch);
    const questionsCurrent = storeQuestions
        .byHouse(nullGUID)
        .filter((t) => t.lesson_type === lesson)
        .map((t) => t.batch);

    const currentLevel = factsCurrent
        .concat(exercisesCurrent)
        .concat(questionsCurrent)
        .reduce((c, i) => (c < i ? i : c), 0);

    // Filter out the cards the level.
    const next = facts
        .concat(exercises, questions)
        .filter((card) => card.batch <= level && card.batch > currentLevel);

    if (next.length === 0) {
        return null;
    }

    return next;
}

export function importExploreCards(versionId, db, cards) {
    cards.forEach((card) => {
        if (!['questions', 'exercises', 'facts'].includes(card.type)) {
            return;
        }

        db[card.type].put(
            Object.fromEntries(
                Object.entries(card)
                    .filter((t) =>
                        [
                            'name',
                            'description',
                            'resource_id',
                            'created_at',
                            'updated_at',
                            'deleted_at',
                            'resource_id',
                            'contains_camara',
                            'batch',
                            'card_image',
                        ].includes(t[0]),
                    )
                    .concat([
                        ['id', v5(card.id, versionId)],
                        ['lesson_type', 'explore'],
                    ]),
            ),
        );
    });
}

export function importLessonsCards(versionId, db, cards) {
    cards.forEach((card) => {
        if (!['questions', 'exercises', 'facts'].includes(card.type)) {
            return;
        }

        if (!card.lesson_type) {
            return;
        }

        db[card.type].put(
            Object.fromEntries(
                Object.entries(card)
                    .filter((t) =>
                        [
                            'name',
                            'description',
                            'resource_id',
                            'created_at',
                            'updated_at',
                            'deleted_at',
                            'contains_camara',
                            'batch',
                            'card_image',
                            'lesson_type',
                        ].includes(t[0]),
                    )
                    .concat([['id', v5(card.id, versionId)]]),
            ),
        );
    });
}

export function debounce(time, callback) {
    let running = false;
    let queued = null;

    let start = (...args) => {
        if (running) {
            queued = args;
            return;
        }

        running = true;
        callback(...args);

        setTimeout(() => {
            running = false;
            if (queued) {
                start(...queued);
                queued = null;
            }
        }, time);
    };

    return start;
}
