import { defineStore } from 'pinia';

export const useStat = defineStore('stat', {
  state: () => ({
    stats: [],
  }),

  getters: {
    all: (state) => state.stats,
    getByIds: (state) => (ids) =>
      state.stats.filter((stat) => ids.includes(stat.id)),
  },

  actions: {
    set(stat) {
      const index = this.stats.findIndex((h) => h.id === stat.id);

      index > -1 ? (this.stats[index] = stat) : this.stats.push(stat);
    },
  },
});
