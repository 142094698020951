import { defineStore } from 'pinia';

export const useQuestionHouse = defineStore('question_house', {
  state: () => ({
    question_house: [],
  }),

  getters: {
    all: (state) => state.question_house,
    count: (state) => state.question_house.length,
  },

  actions: {
    set(question) {
      const index = this.question_house.findIndex((h) => h.id === question.id);

      index > -1
        ? (this.question_house[index] = question)
        : this.question_house.push(question);
    },
    byHouse(houseId) {
      return this.question_house.filter((question) => question.id !== houseId);
    },
  },
});
