import { v4 } from 'uuid';
import { defineStore } from 'pinia';

export const useUser = defineStore('user', {
  state: () => ({
    user: null,
    loggedIn: false,
    fetched: false,
  }),

  getters: {
    onboarded(state) {
      return (
        state.user?.onboarding_completed_at !== undefined &&
        state.user?.onboarding_completed_at !== null
      );
    },
  },

  actions: {
    setUser(user) {
      this.user = user;
      localStorage.setItem('user', user);
    },
    setLoggedIn(loggedIn) {
      this.loggedIn = +loggedIn;
      localStorage.setItem('loggedIn', +loggedIn);
    },
    setOnboarded() {
      this.user.onboarding_completed_at = new Date()
        .toISOString()
        .replace('Z', '000Z');
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    async get() {
      // Retrieve the locally stored user or simply create one.
      let user;

      try {
        user = JSON.parse(localStorage.getItem('user'));

        if (!user || user === 'null') {
          throw new Error('Bad user.');
        }
      } catch (e) {
        user = {
          id: v4(),
          onboarding_completed_at: null,
        };
      }

      this.$patch({
        user: user,
        loggedIn: true,
        fetched: true,
      });
    },
  },
});
