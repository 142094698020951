import { defineStore } from 'pinia';
import { useUser } from './user';

export const useHouse = defineStore('house', {
  state: () => ({
    houses: [],
  }),

  getters: {
    all(state) {
      const user = useUser();

      // Retrieve the houses owned by this user.
      return state.houses.filter(
        (house) => house.user_id === user.$state.user.id,
      );
    },
    house: (state) => state.houses?.[0],
  },

  actions: {
    set(house) {
      const index = this.houses.findIndex((h) => h.id === house.id);

      index > -1 ? (this.houses[index] = house) : this.houses.push(house);
    },
  },
});
