import { useUser } from './stores/user';
import { createRouter, createWebHistory } from 'vue-router';

/*
|------------------------------------------------------------------------------
| Routes
|------------------------------------------------------------------------------
*/
export const router = createRouter({
  history: createWebHistory(),

  routes: [
    /*
    |--------------------------------------------------------------------------
    | Application routes.
    |--------------------------------------------------------------------------
    */
    {
      path: '/',
      component: () => import('@/layouts/Application.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/application/MyHome.vue'),
          meta: { title: 'Mijn huis', menu: true, compare: false },
        },
        {
          path: 'mijn-dag',
          name: 'my-day',
          component: () => import('@/views/application/MyDay.vue'),
          meta: { title: 'Mijn dag', menu: true, compare: false },
        },
        {
          path: 'ontdekken',
          name: 'explore',
          component: () => import('@/views/application/Explore.vue'),
          meta: {
            title: 'Ontdekkingen',
            menu: true,
            compare: false,
            grid: 'explore.index',
          },
          children: [
            {
              path: 'overzicht',
              name: 'explore.index',
              component: () => import('@/views/application/explore/Grid.vue'),
              meta: {
                title: 'Ontdekkingen',
                menu: false,
                compare: false,
                back: { name: 'explore' },
                grid: false,
              },
            },
            {
              path: 'weetjes/:id?',
              name: 'explore.facts',
              component: () => import('@/views/application/explore/Facts.vue'),
            },
            {
              path: 'opdrachten/:id?',
              name: 'explore.exercises',
              component: () =>
                import('@/views/application/explore/Exercises.vue'),
            },
            {
              path: 'vraagstukken/:id?',
              name: 'explore.questions',
              component: () =>
                import('@/views/application/explore/Questions.vue'),
            },
          ],
        },
        // Lesson
        // {
        //   path: 'lessen',
        //   name: 'lesson',
        //   component: () => import('@/views/application/Lesson.vue'),
        //   meta: { title: 'Lessen', menu: true, compare: false },
        //   children: [
        //     {
        //       path: 'lesson/:lesson/:id?',
        //       name: 'lessons.show',
        //       component: () => import('@/views/application/lessons/Show.vue'),
        //     },
        //   ],
        // },
        {
          path: 'uitleg/:resource?',
          name: 'explanation.show',
          component: () => import('@/views/application/Explanation.vue'),
          meta: {
            title: 'Uitleg',
            toolbar: false,
            back: { name: 'home' },
            compare: false,
          },
        },

        // Objects
        {
          path: 'objecten/nieuw/:id?',
          name: 'objects.create',
          component: () => import('@/views/application/objects/Create.vue'),
          meta: {
            title: 'Nieuw voorwerp',
            toolbar: false,
            back: { name: 'home' },
            compare: false,
          },
        },
        {
          path: 'objecten/bewerk/:id',
          name: 'objects.edit',
          component: () => import('@/views/application/objects/Create.vue'),
          meta: {
            title: 'Bewerk voorwerp',
            toolbar: false,
            back: { name: 'home' },
            compare: false,
          },
        },
        {
          path: 'objecten',
          name: 'objects.index',
          component: () => import('@/views/application/objects/Index.vue'),
          meta: {
            title: 'Voorwerpen hergebruiken',
            toolbar: false,
            back: { name: 'home' },
            compare: false,
          },
        },

        // Actions
        {
          path: 'acties/nieuw/:id?',
          name: 'actions.create',
          component: () => import('@/views/application/actions/Create.vue'),
          meta: {
            title: 'Iets nieuws',
            toolbar: false,
            back: { name: 'my-day' },
            compare: false,
          },
        },
        {
          path: 'acties/bewerk/:id',
          name: 'actions.edit',
          component: () => import('@/views/application/actions/Create.vue'),
          meta: {
            title: 'Bewerk actie',
            toolbar: false,
            back: { name: 'my-day' },
            compare: false,
          },
        },
        {
          path: 'acties/toevoegen',
          name: 'actions.index',
          component: () => import('@/views/application/actions/Index.vue'),
          meta: {
            title: 'Eerder gedaan',
            toolbar: false,
            back: { name: 'my-day' },
            compare: false,
          },
        },

        // Days
        {
          path: 'dagen/:id?',
          name: 'days.index',
          component: () => import('@/views/application/days/Index.vue'),
          meta: {
            title: 'Mijn dagen',
            toolbar: false,
            back: { name: 'my-day' },
            compare: true,
          },
        },
        {
          path: 'dagen/vergelijk/:dayA/:dayB',
          name: 'days.compare',
          component: () => import('@/views/application/days/Compare.vue'),
          meta: {
            title: 'Vergelijken',
            toolbar: false,
            back: { name: 'days.index' },
            compare: false,
          },
        },
        {
          path: 'dagen/gemiddelde/:dayA',
          name: 'days.average',
          component: () => import('@/views/application/days/Average.vue'),
          meta: {
            title: 'Vergelijken',
            toolbar: false,
            back: { name: 'days.index' },
            compare: false,
          },
        },
      ],
    },

    /*
    |--------------------------------------------------------------------------
    | Onboarding routes.
    |--------------------------------------------------------------------------
    */

    {
      path: '/welkom/:id(\\d)',
      name: 'onboarding',
      component: () => import('@/views/onboarding/Onboarding.vue'),
    },

    /*
    |--------------------------------------------------------------------------
    | Authentication routes.
    |--------------------------------------------------------------------------
    */
    {
      path: '/welkom',
      component: () => import('@/layouts/Authentication.vue'),
      children: [
        {
          path: 'inloggen',
          name: 'auth/login',
          component: () => import('@/views/auth/Login.vue'),
        },
      ],
    },
  ],
});

/*
|------------------------------------------------------------------------------
| Middleware
|------------------------------------------------------------------------------
*/
router.beforeEach((to) => {
  const user = useUser();

  // Redirect to onboarding if not yet onboarded.
  if (!user.onboarded && to.name !== 'onboarding') {
    return { name: 'onboarding', params: { id: 1 } };
  }
});
