import { v4 } from 'uuid';
import { useHouse } from './house';
import { defineStore } from 'pinia';

export const useDay = defineStore('day', {
  state: () => ({
    days: [],
  }),

  getters: {
    all: (state) => state.days,
  },

  actions: {
    set(day) {
      const index = this.days.findIndex((h) => h.id === day.id);

      index > -1 ? (this.days[index] = day) : this.days.push(day);
    },
    removeById(id) {
      const index = this.days.findIndex((h) => h.id === id);

      if (index === -1) {
        return;
      }

      this.days[index] = undefined;
      this.days = this.days.filter((i) => i !== undefined);
    },
    remove(day) {
      return this.removeById(day.id);
    },
    async byDate(date) {
      return new Promise((resolve) => {
        const iso = date.toISOString().split('T')[0];
        const house = useHouse().house;

        resolve(
          this.days.find(
            (day) => day.date === iso && day.house_id === house.id,
          ),
        );
      });
    },
    async create(date) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        // Dont create the day if the day already exists.
        const day = await this.byDate(date);
        if (day) {
          return reject('Ded');
        }

        const house = useHouse().house;

        // Create the day.
        const newDay = {
          id: v4(),
          house_id: house.id,
          date: date.toISOString().split('T')[0],
          created_at: new Date(),
          updated_at: new Date(),
          deleted_at: null,
        };

        this.set(newDay);
        resolve(newDay);
      });
    },
  },
});
