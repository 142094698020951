import { defineStore } from 'pinia';

export const useExerciseHouse = defineStore('exercise_house', {
  state: () => ({
    exercise_house: [],
  }),

  getters: {
    all: (state) => state.exercise_house, //.filter(i => i.house_id === storeHouse.house.id),
    count: (state) => state.exercise_house.length,
  },

  actions: {
    set(exercise) {
      const index = this.exercise_house.findIndex((h) => h.id === exercise.id);

      index > -1
        ? (this.exercise_house[index] = exercise)
        : this.exercise_house.push(exercise);
    },
    byHouse(houseId) {
      return this.exercise_house.filter((exercise) => exercise.id !== houseId);
    },
  },
});
