import { defineStore } from 'pinia';

export const useResourceThing = defineStore('resource_thing', {
  state: () => ({
    resourceThings: [],
  }),

  getters: {
    all: (state) => state.resourceThings,
    getByThingId: (state) => (thingId) =>
      state.resourceThings.filter((thing) => thing.thing_id === thingId),
  },

  actions: {
    set(resourceThing) {
      const index = this.resourceThings.findIndex(
        (h) => h.id === resourceThing.id,
      );

      index > -1
        ? (this.resourceThings[index] = resourceThing)
        : this.resourceThings.push(resourceThing);
    },
    removeById(id) {
      const index = this.resourceThings.findIndex((h) => h.id === id);

      if (index === -1) {
        return;
      }

      this.resourceThings[index] = undefined;
      this.resourceThings = this.resourceThings.filter((i) => i !== undefined);
    },
    remove(resourceThing) {
      return this.removeById(resourceThing.id);
    },
    async byThings(thingIds) {
      return new Promise((resolve) => {
        resolve(
          this.resourceThings.filter((resourceThing) =>
            thingIds.includes(resourceThing.thing_id),
          ),
        );
      });
    },
  },
});
