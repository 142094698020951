import { only } from '@/helpers';
import { defineStore } from 'pinia';
import { useQuestionHouse } from './questionHouse';

export const useQuestion = defineStore('questions', {
  state: () => ({
    questions: [],
  }),

  getters: {
    all: (state) => state.questions,
    count: (state) => state.questions.length,
  },

  actions: {
    set(question) {
      const index = this.questions.findIndex((h) => h.id === question.id);

      index > -1
        ? (this.questions[index] = question)
        : this.questions.push(question);
    },
    byHouse(houseId) {
      return useQuestionHouse()
        .byHouse(houseId)
        .map((item) =>
          Object.assign(
            only(['finished_at', 'read_at', 'image'], item),
            this.questions.find((t) => t.id === item.question_id),
          ),
        );
    },
  },
});
