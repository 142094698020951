import { defineStore } from 'pinia';

export const useFactHouse = defineStore('fact_house', {
  state: () => ({
    fact_house: [],
  }),

  getters: {
    all: (state) => state.fact_house, //.filter(i => i.house_id === storeHouse.house.id),
    count: (state) => state.fact_house.length,
  },

  actions: {
    set(fact) {
      const index = this.fact_house.findIndex((h) => h.id === fact.id);

      index > -1 ? (this.fact_house[index] = fact) : this.fact_house.push(fact);
    },
    byHouse(houseId) {
      return this.fact_house.filter((fact) => fact.id !== houseId);
    },
  },
});
